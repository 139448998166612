/*--------------------------------------------------------------
HEADER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Header
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Header
--------------------------------------------------------------*/
var header = {
	h: $$.id('header'),
	cache: $$.first('#menu .centre'),
	init: function(){
		window.addEventListener('scroll', function(e){
			if(window.pageYOffset > 300) header.fix();
			else header.unfix();
		});
	},
	fix: function(add){
		this.h.className = add ? 'fix ' + add : 'fix';
		this.cache.className = 'centre up';
	},
	unfix: function(force){
		if(!document.body.classList.contains('histoire') || force) this.h.className = '';
		this.cache.className = 'centre';
	}
};
header.init();