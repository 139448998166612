/*--------------------------------------------------------------
WHEEL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Mois
- Wheel
- Init
- Autoplay
- Point
- Down
- Move
- Up
- Handle
- Stroke
- Math
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Mois
--------------------------------------------------------------*/
var mois = [
	'janvier',
	'février',
	'mars',
	'avril',
	'mai',
	'juin',
	'juillet',
	'août',
	'septembre',
	'octobre',
	'novembre',
	'décembre'
];



/*--------------------------------------------------------------
	Wheel
--------------------------------------------------------------*/
function Wheel(id){
	this.dom = $$.id(id);
	this.stroke = this.dom.get('circle.s');
	this.dashTotal = parseInt(this.stroke.getAttribute('stroke-dasharray'));
	this.points = this.dom.getAll('.points span');
	this.text = this.dom.get('.texte');
	this.handle = this.dom.get('.handle');
	this.active = false;
	this.init();
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
Wheel.prototype.init = function(){
	var $ = this;
	this.autoplay(2400);
	this.points.forEach(function(el, index){
		['mousedown', 'touchstart'].forEach(function(listener){
			el.on(listener, function(e){
				e.preventDefault();
				$.active = true;
				$.down(e, el, index);
			});
		});
	});
	['mousemove', 'touchmove'].forEach(function(listener){
		document.body.on(listener, function(e){
			if(!$.active) return;
			$.move(e);
		});
	});
	['mouseup', 'touchend'].forEach(function(listener){
		document.body.on(listener, function(e){
			if(!$.active) return;
			$.active = false;
			$.up();
		});
	});
};



/*--------------------------------------------------------------
	Autoplay
--------------------------------------------------------------*/
Wheel.prototype.autoplay = function(timeout){
	var $ = this;
	var bol = false;
	this.range = 0;
	this.start = 0;
	$$.first('.intro').className = 'intro auto';
	['mousedown', 'touchstart'].forEach(function(listener){
		$$.first('.intro').on(listener, function(){
			$$.first('.intro').className = 'intro';
			bol = true;
		});
	});
	$$.timeout(timeout, function(){
		if(bol) return;
		$.range = 2;
		$.points[0].className = 'end';
		$.text.innerHTML = '<span>de janvier</span> à mars';
		$.stroke.style.strokeDashoffset = $.dashTotal - $.m.deg2stroke.call($, 60);
		$.points[1].className = 'in';
		$.points[2].className = 'end';
		$$.timeout(1000, function(){
			if(bol) return;
			$.point(3);
		});
		$$.timeout(1600, function(){
			if(bol) return;
			$.point(4);
		});
		$$.timeout(2600, function(){
			if(bol) return;
			$.point(0);
			$$.first('.intro').className = 'intro';
		});
	});
};



/*--------------------------------------------------------------
	Point
--------------------------------------------------------------*/
Wheel.prototype.point = function(num){
	this.range = 0;
	this.start = num;
	for(var i in this.points){
		if (i !== 'length') {
			this.points[i].className = '';
		}
	}
	this.points[num].className = 'end';
	var length = this.m.deg2stroke.call(this, 0);
	this.stroke.style.strokeDashoffset = this.dashTotal - length;
	this.stroke.setAttribute('transform', 'rotate(' + this.start*30 + ' 212 212)');
	// this.stroke.style.transform = 'rotate(' + this.start*30 + 'deg)';
	this.text.innerHTML = mois[this.start];
};



/*--------------------------------------------------------------
	Down
--------------------------------------------------------------*/
Wheel.prototype.down = function(e, el, index){
	var $ = this;
	this.points.forEach(function(p){
		p.className = '';
	});
	el.className = 'end';
	this.angle = this.m.getAngle.call(this, e);
	this.range = 0;
	this.start = index;
	this.s.pos.call(this, index);
	this.h.popIn.call(this, e);
	this.text.textContent = mois[index];
};



/*--------------------------------------------------------------
	Move
--------------------------------------------------------------*/
Wheel.prototype.move = function(e){
	this.oldAngle = this.angle;
	this.angle = this.m.getAngle.call(this, e);
	this.h.pos.call(this);
	var angle = Math.round(this.angle/30);
	var oldAngle = Math.round(this.oldAngle/30);
	if(oldAngle != angle){
		this.s.length.call(this);
		if(angle == 12) angle = 0;
		this.text.innerHTML = this.range == 0 ? mois[this.start] : '<span>de ' + mois[this.start] + '</span> à ' + mois[angle];
		this.points.forEach(function(el){
			el.classList.remove('in');
		});
		for(var i = this.range; i > 0; i--){
			this.points[(i+this.start)%12].classList.add('in');
		}
	}
};



/*--------------------------------------------------------------
	Up
--------------------------------------------------------------*/
Wheel.prototype.up = function(){
	this.h.popOut.call(this);
	var last = Math.round(this.angle/30);
	if(last == 12) last = 0;
	this.points[last].className = 'end';
};



/*--------------------------------------------------------------
	Handle
--------------------------------------------------------------*/
Wheel.prototype.h = {
	pos: function(e){
		this.handle.style.transform = this.handle.style.webkitTransform = 'rotate('+this.angle+'deg)';
	},
	popIn: function(e){
		this.h.pos.call(this, e);
		this.handle.classList.add('on');
	},
	popOut: function(){
		this.handle.classList.remove('on');
	}
};



/*--------------------------------------------------------------
	Stroke
--------------------------------------------------------------*/
Wheel.prototype.s = {
	pos: function(){
		var offset = this.m.deg2stroke.call(this, this.start*30);
		this.stroke.setAttribute('transform', 'rotate(' + this.start*30 + ' 212 212)');
		// this.stroke.style.transform = 'rotate(' + this.start*30 + 'deg)';
		this.stroke.style.strokeDashoffset = this.dashTotal;
	},
	length: function(){
		var length = this.angle - this.start*30;
		if (length < 0) length += 360;
		this.range = Math.round(length/30);
		length = this.m.deg2stroke.call(this, this.range*30);
		this.stroke.style.strokeDashoffset = this.dashTotal - length;
	}
};



/*--------------------------------------------------------------
	Math
--------------------------------------------------------------*/
Wheel.prototype.m = {
	deg2stroke: function(deg){
		return deg*this.dashTotal/360;
	},
	getAngle: function(e){
		var x = e.clientX || e.touches[0].clientX;
		var y = e.clientY || e.touches[0].clientY;
		var cx = this.dom.clientWidth/2 + this.dom.getBoundingClientRect().left;
		var cy = this.dom.clientHeight/2 + this.dom.getBoundingClientRect().top;
		return (Math.atan2(x-cx, -(y-cy))*(180/Math.PI)+360)%360;
	}
};
