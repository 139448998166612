/*--------------------------------------------------------------
SAVOIR-FAIRE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Init
- Destroy
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.savoir = function(){
	parallaxes.init();
}



/*--------------------------------------------------------------
	Destroy
--------------------------------------------------------------*/
destroy.savoir = function(){
	parallaxes.destroy();
};