/*--------------------------------------------------------------
MENU  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Toggle / Close
- Backgrounds
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Toggle / Close
--------------------------------------------------------------*/
toggle.menu = function(){
	if(!$$.id('menu').classList.contains('open')) $$.id('menu').scrollTop = 0;
	$$.id('burger').classList.toggle('open');
	$$.id('menu').classList.toggle('open');
	document.documentElement.classList.toggle('menuOpen');
	scroll.toggle();
};
close.menu = function(fast) {
	document.documentElement.classList.remove('menuOpen');
	$$.id('burger').className = '';
	if(!fast){
		$$.id('menu').className = '';
		return;
	}
	$$.id('menu').className = 'fast';
	scroll.enable();
	$$.timeout(500, function(){
		$$.id('menu').className = '';
	});
}



/*--------------------------------------------------------------
	Backgrounds
--------------------------------------------------------------*/
var menu = {
	bgs: $$.all('.bgs div'),
	links: $$.all('#menu .submenu li'),
	init: function(){
		this.links.forEach(function(el, index){
			el.onmouseover = function(){
				menu.show(index);
			};
			el.onmouseleave = function(){
				menu.hide(index);
			};
		});
	},
	show: function(index){
		this.bgs[index].className = 'visible';
	},
	hide: function(index){
		this.bgs[index].className = '';
	}
};
menu.init();