window.$$ = new function() {
	var _this = this;

	this.first = function(el) {
		return document.body.querySelector(el);
	};
	this.all = function(el) {
		return document.body.querySelectorAll(el);
	};
	this.id = function(el) {
		return document.getElementById(el);
	};
	this.class = function(el) {
		return document.getElementsByClassName(el);
	};
	this.tag = function(el) {
		return document.getElementsByTagName(el)[0];
	};
	this.name = function(el) {
		return document.getElementsByName(el)[0];
	};
	this.ready = function(func) {
		document.addEventListener('DOMContentLoaded', func);
	};
	this.scroll = function(func) {
		document.addEventListener('scroll', func);
	};
	this.ajax = new function() {
		var $ = this;
		this.parseData = function(data) {
			if (data && data.constructor.name == 'FormData') {
				$.dataUrl = data;
			} else {
				$.xhttp.setRequestHeader(
					'Content-type',
					'application/x-www-form-urlencoded'
				);
				$.dataUrl = [];
				for (key in data) {
					$.dataUrl.push(key + '=' + encodeURIComponent(data[key]));
				}
				$.dataUrl = $.dataUrl.join('&');
			}
		};
		this.get = function(url, data) {
			$.xhttp = new XMLHttpRequest();
			$.parseData(data);
			$.xhttp.open('GET', url + '?' + $.dataUrl, true);
			$.xhttp.send();
			return $.promise;
		};
		this.post = function(url, data) {
			$.xhttp = new XMLHttpRequest();
			$.xhttp.open('POST', url, true);
			$.parseData(data);
			$.xhttp.send($.dataUrl);
			return $.promise;
		};
		this.upload = function(url, file, name) {
			// works only from IE10
			$.formData = new FormData();
			$.formData.append(name, file, file.name);
			$.xhttp = new XMLHttpRequest();
			$.xhttp.open('POST', url, true);
			$.xhttp.send($.formData);
			return $.promise;
		};
		this.promise = new function() {
			this.then = function(callback) {
				$.xhttp.onreadystatechange = function() {
					if (this.readyState == 4) callback(this.responseText);
				};
			};
			return this;
		}();
		return this;
	}();
	this.timeout = function(duration, callback) {
		var start = null;
		var animFrame =
			window.requestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.msRequestAnimationFrame;
		function step(timestamp) {
			if (start === null) start = timestamp;
			if (timestamp - start < duration) animFrame(step);
			else callback();
		}
		animFrame(step);
	};
	this.repeat = function(duration, func) {
		var start = null;
		var animFrame =
			window.requestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.msRequestAnimationFrame;
		function step(timestamp) {
			if (start === null) start = timestamp;
			if (timestamp - start > duration) return;
			func();
			animFrame(step);
		}
		animFrame(step);
	};
	this.index = function(el) {
		var els = el.parentNode.children;
		return Array.prototype.indexOf.call(els, el);
	};
	this.setCookie = function(name, value, expirationDays) {
		var d = new Date();
		d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000);
		var expires = 'expires=' + d.toUTCString();
		document.cookie = name + '=' + value + ';' + expires + ';path=/';
	};
	this.getCookie = function(name) {
		var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
		return v ? v[2] : null;
	};
	this.deleteCookie = function(name) {
		_this.setCookie(name, '', -1);
	};

	return this;
}();
HTMLElement.prototype.styles = function(styles) {
	for (var i in styles) this.style[i] = styles[i];
};
HTMLElement.prototype.on = function(e, func) {
	this.addEventListener(e, func);
};
HTMLElement.prototype.removeChildren = function() {
	while (this.firstChild) this.removeChild(this.firstChild);
};
NodeList.prototype.forEach = Array.prototype.forEach;
HTMLElement.prototype.get = function(el) {
	return this.querySelector(el);
};
HTMLElement.prototype.getAll = function(el) {
	return this.querySelectorAll(el);
};

// Interval
function Interval(duration, callback) {
	this.callback = callback;
	this.duration = duration;
	this.start = null;
	this.timeout = null;
	this.init();
}
Interval.prototype.request =
	window.requestAnimationFrame ||
	window.mozRequestAnimationFrame ||
	window.webkitRequestAnimationFrame ||
	window.msRequestAnimationFrame;
Interval.prototype.cancel =
	window.cancelAnimationFrame || window.mozCancelAnimationFrame;
Interval.prototype.init = function() {
	var $ = this;
	function step(timestamp) {
		if ($.start === null) $.start = timestamp;
		if (timestamp - $.start < $.duration) {
			$.timeout = $.request.call(window, step);
		} else {
			$.callback();
			$.start = timestamp;
			$.timeout = $.request.call(window, step);
		}
	}
	this.timeout = this.request.call(window, step);
};
Interval.prototype.stop = function() {
	this.cancel.call(window, this.timeout);
};

// Anim
function Anim(func) {
	this.func = func;
	this.timeout = null;
	this.start();
}
Anim.prototype.request =
	window.requestAnimationFrame ||
	window.mozRequestAnimationFrame ||
	window.webkitRequestAnimationFrame ||
	window.msRequestAnimationFrame;
Anim.prototype.cancel =
	window.cancelAnimationFrame || window.mozCancelAnimationFrame;
Anim.prototype.start = function() {
	var $ = this;
	function step(timestamp) {
		$.func();
		$.timeout = $.request.call(window, step);
	}
	this.timeout = this.request.call(window, step);
};
Anim.prototype.stop = function() {
	this.cancel.call(window, this.timeout);
};
