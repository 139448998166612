/*--------------------------------------------------------------
ACTUS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Carousel
- Ajax
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Carousel
--------------------------------------------------------------*/
function ActusSlider(id){
	this.dom = $$.id(id);
	this.ul = this.dom.get('ul');
	this.li = this.dom.get('li');
	this.nbrLis = this.ul.getAll('li').length;
	this.increment = this.li.offsetWidth;
	this.slideNbr = 0;
	this.init();
};
ActusSlider.prototype.init = function(){
	var $ = this;
	window.addEventListener('resize', function(){
		$.increment = $.li.offsetWidth;
		$.slideNbr = 0;
		$.move();
	});
};
ActusSlider.prototype.left = function(){
	if(this.slideNbr > 0) this.slideNbr--;
	this.move();
};
ActusSlider.prototype.right = function(){
	var liste = this.ul.parentNode;
	var nbr = Math.round(liste.clientWidth/this.li.clientWidth);
	if(this.nbrLis - nbr > this.slideNbr) this.slideNbr++;
	this.move();
};
ActusSlider.prototype.move = function(){
	this.ul.style.transform = this.ul.style.webkitTransform = "translateX(" + (-this.increment*this.slideNbr) + "px)";
};
var carHead = new ActusSlider('carHead');



/*--------------------------------------------------------------
	Ajax
--------------------------------------------------------------*/
var actus = {
	loading: false,
	last: 0,
	popup: $$.id('popupActu'),
	cache: $$.first('#popupActu+.cache'),
	index: 0,
	get: function(id){
		if(this.loading) return;
		actus.cache.className = 'cache visible';
		if(actus.last == id){
			actus.popup.className = 'visible';
			return;
		}
		this.loading = true;
		$$.ajax.post('/?p=' + id).then(function(data){
			actus.popup.innerHTML = data;
			actus.blocs = actus.popup.getAll('.bloc');
			actus.nav = actus.popup.get('.nav');
			actus.last = id;
			actus.index = 0;
			$$.timeout(100, function(){
				actus.popup.className = 'visible';
				actus.loading = false;
			});
		});
	},
	close: function(){
		if(this.loading) return;
		this.popup.className = '';
		this.cache.className = 'cache';
	},
	left: function(){
		if(!this.nav.classList.contains('first'))
			this.move(-1);
	},
	right: function(){
		if(!this.nav.classList.contains('last'))
			this.move(1);
	},
	move: function(dir){
		this.blocs[this.index].className = 'bloc';
		this.nav.classList.remove('last', 'first');
		this.index += dir;
		this.nav.className = this.blocs.length == this.index+1 ? 'nav last' : !this.index ? 'nav first' : 'nav';
		this.blocs[this.index].className = 'bloc active';
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
var carHome;
init.home = function(){
	carHome = new ActusSlider('carHome');
};