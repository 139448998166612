/*--------------------------------------------------------------
PARALLAX  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Parallax
- Init
- Autoplay
- Calc
- inView
- imgFull
- Parallaxes
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Parallax
--------------------------------------------------------------*/
function Parallax(selector){
	this.dom = selector;
	this.p = selector;
	this.w = this.p.firstChild;
	this.i = this.w.firstChild;
	this.init();
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
Parallax.prototype.init = function(){
	var $ = this;
	this.imgFull();
	this.i.onload = function(){
		$.imgFull();
	};
	if(!isTouch) this.autoplay();
};



/*--------------------------------------------------------------
	Autoplay
--------------------------------------------------------------*/
Parallax.prototype.autoplay = function(){
	var $ = this;
	$$.timeout(1400, function(){
		$$.repeat(1000, function(){
			$.calc();
			reveal.inview();
		});
	});
};



/*--------------------------------------------------------------
	Calc
--------------------------------------------------------------*/
Parallax.prototype.calc = function(){
	this.pH = this.p.clientHeight;
	this.winH = window.innerHeight;
	this.offset = this.p.getBoundingClientRect().top;
	if(this.inView()){
		var a = this.i.clientHeight, x = this.offset, y = this.winH, z = this.pH;
		var offsetPx = (-2*a*x+a*y+(-y+2*x-a)*z+z*z)/(2*z+2*y);
		this.w.style.transform = this.w.style.webkitTransform = "translateY(" + offsetPx + "px)";
	}
};



/*--------------------------------------------------------------
	inView
--------------------------------------------------------------*/
Parallax.prototype.inView = function(){
	return (this.offset - this.winH < 0 && this.offset + this.pH > 0);
};



/*--------------------------------------------------------------
	imgFull
--------------------------------------------------------------*/
Parallax.prototype.imgFull = function(){
	this.pH = this.p.clientHeight;
	this.pW = this.p.clientWidth;
	this.i.className = this.i.clientHeight < this.pH || this.i.clientWidth > this.pW ? 'inverse' : '';
};



/*--------------------------------------------------------------
	Parallaxes
--------------------------------------------------------------*/
var parallaxes = {
	p: [],
	init: function(){
		$$.all('.parallax').forEach(function(el, i){
			parallaxes.p[i] = new Parallax(el);
		});
		window.addEventListener('resize', this.full);
		if(isTouch) return;
		window.addEventListener('scroll', this.calc);
		window.addEventListener('resize', this.calc);
	},
	destroy: function(){
		parallaxes.p = [];
		window.removeEventListener('resize', this.full);
		if(isTouch) return;
		window.removeEventListener('scroll', this.calc);
		window.removeEventListener('resize', this.calc);
	},
	full: function(){
		for(var i in parallaxes.p){
			parallaxes.p[i].imgFull();
		}
	},
	calc: function(){
		for(var i in parallaxes.p){
			parallaxes.p[i].calc();
		}
	}
};