/*--------------------------------------------------------------
CALENDRIER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Polyfill
- Calendrier
- Scroll
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Polyfill
--------------------------------------------------------------*/
if(!Math.sign){
	Math.sign = function(x){
		x = +x;
		if (x === 0 || isNaN(x)){
			return Number(x);
		}
		return x > 0 ? 1 : -1;
	};
}



/*--------------------------------------------------------------
	Calendrier
--------------------------------------------------------------*/
var calendrier = {
	init: function(roue){
		this.wrap = $$.id('calWrap');
		this.cal = $$.id('calendrier');
		this.intro = $$.first('.intro');
		this.mois = [];
		this.moisR = $$.all('#reserveCal>section');
		this.current = -1;
		this.range = [];
		this.roue = roue;
		this.h = $$.id('header');
		this.progress = false;
		this.fake = document.createElement('div');
		calScroll.init();
		if(window.innerWidth < 426){
			this.roue.range = 11;
			this.filter();
		}
	},
	filter: function(){
		this.range = [];
		var range = this.roue.range == 12 ? 11 : this.roue.range;
		for(var i = 0; i <= range; i++) {
			this.range.push((this.roue.start+i)%12);
		}
		this.clone();
		this.down();
	},
	clone: function(){
		for(var i in this.range){
			this.mois[i] = this.moisR[this.range[i]].cloneNode(true);
			this.cal.appendChild(this.mois[i]);
		}
	},
	down: function(){
		if(window.innerWidth < 426) return;
		this.current++;
		this.preAnim();
		this.bind();
		if(this.current == 0){
			this.start();
		}else{
			this.p.className = 'visible anim out';
			var offset = this.m.getBoundingClientRect().top;
			this.m.style.transform = this.m.style.webkitTransform = 'translateY(-' + offset + 'px)';
		}
		calendrier.postAnim();
	},
	up: function(){
		if(window.innerWidth < 426) return;
		this.current--;
		this.preAnim();
		if(this.current < 0){
			this.quit();
		}else{
			this.bind();
			this.m.className = 'visible anim out';
			this.n.className = 'visible anim fix';
			$$.timeout(100, function(){
				calendrier.m.className = 'visible anim';
				calendrier.n.style.transform = calendrier.n.style.webkitTransform = 'translateY(' + window.innerHeight + 'px)';
			});
		}
		calendrier.postAnim();
	},
	preAnim: function(){
		scroll.disable();
		this.progress = true;
		for(var i in this.mois){
			this.mois[i].className = '';
		}
		this.p = this.mois[this.current-1] || this.fake;
		this.m = this.mois[this.current] || this.fake;
		this.n = this.mois[this.current+1] || this.fake;
		this.m.className = 'visible anim';
		this.h.className = 'hidden';
		this.n.className = 'preview';
	},
	postAnim: function(){
		$$.timeout(1000, function(){
			calendrier.h.className = '';
			calendrier.m.className = 'visible';
			calendrier.p.className = '';
			calendrier.n.className = 'preview';
			calendrier.m.style.transform = calendrier.m.style.webkitTransform = '';
			calendrier.n.style.transform = calendrier.n.style.webkitTransform = '';
			calendrier.progress = false;
			window.scrollTo(0, 0);
			scroll.enable();
		});
	},
	start: function(){
		// this.cal.style.transform = this.cal.style.webkitTransform = 'translateY('+window.innerHeight + 'px)';
		this.intro.className = 'intro hidden';
		this.wrap.className = 'visible';
	},
	quit: function(){
		this.intro.className = 'intro';
		this.wrap.className = '';
		this.n.className = 'visible';
		this.mois = [];
		$$.timeout(1000, function(){
			calendrier.cal.removeChildren();
		});
	},
	go2Start: function(){
		this.current = -1;
		scroll.disable();
		this.progress = true;
		this.h.className = 'hidden';
		this.quit();
		$$.timeout(1000, function(){
			calendrier.h.className = '';
			calendrier.progress = false;
			scroll.enable();
		});
	},
	bind: function(){
		this.mois[this.current].firstElementChild.onclick = function(){
			if(calendrier.progress) return;
			calendrier.up();
		};
		if(this.mois[this.current+1]){
			this.mois[this.current+1].firstElementChild.onclick = function(){
				if(calendrier.progress) return;
				calendrier.down();
			};
		}
	}
}



/*--------------------------------------------------------------
	Scroll
--------------------------------------------------------------*/
var calScroll = {
	init: function(){
		this.int = 0;
		window.addEventListener('mousewheel', this.scroll);
		window.addEventListener('DOMMouseScroll', this.scroll);
	},
	destroy: function(){
		window.removeEventListener('mousewheel', this.scroll);
		window.removeEventListener('DOMMouseScroll', this.scroll);
	},
	scroll: function(e){
		var delta = -Math.sign(e.wheelDelta || -e.detail);
		if(calendrier.progress || calendrier.current < 0) return;
		if(window.pageYOffset == 0 || (window.innerHeight + window.pageYOffset) >= calendrier.cal.clientHeight){
			calScroll.int += delta;
		}else{
			calScroll.int = 0;
		}
		if(calScroll.int > 10){
			e.preventDefault();
			calScroll.int = 0;
			if(calendrier.current >= calendrier.mois.length-1) return;
			calendrier.down();
		}else if(calScroll.int < -10){
			calScroll.int = 0;
			calendrier.up();
		}
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
var roue = null;
init.calendrier = function(){
	roue = new Wheel('roue');
	calendrier.init(roue);
}



/*--------------------------------------------------------------
	Destroy
--------------------------------------------------------------*/
destroy.calendrier = function(){
	roue = null;
	calScroll.destroy();
};
