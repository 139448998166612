/*--------------------------------------------------------------
AJAX  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Page
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Page
--------------------------------------------------------------*/
var page = {
	bol: false,
	cache: $$.id('pageCache'),
	main: $$.id('main'),
	old: window.location.href,
	init: function(){
		page.bind();
		window.onpopstate = page.change;
	},
	href: function(e){
		e.preventDefault();
		var el = e.target.nodeName == 'A' ? e.target : e.target.parentNode;
		if(el.nodeName != 'A') return;
		if(page.old == el.href){
			close.menu();
			return;
		}
		history.pushState(null, null, el.href);
		page.change();
	},
	change: function(){
		if(page.bol) return;
		page.bol = true;
		page.url = window.location.href;
		if(page.old.indexOf('gamme-produit') > 0 && page.url.indexOf('gamme/') > 0){ // menu > single
			gamme.ajax.ms();
			return;
		}else if(page.old.indexOf('gamme/') > 0 && page.url.indexOf('gamme/') > 0){ // single > single
			gamme.ajax.ss();
			return;
		}else if(page.old.indexOf('gamme/') > 0 && page.url.indexOf('gamme-produit') > 0){ // single > menu
			gamme.ajax.sm();
			return;
		}
		page.old = page.url;
		page.call();
	},
	call: function(){
		page.hide();
		$$.timeout(500, function(){
			page.destroy();
			$$.ajax.post(page.url).then(function(res){
				page.parse(res);
				page.replace();
				page.bind();
				page.show();
				$$.timeout(500, function(){
					page.bol = false;
				});
			});
		});
	},
	hide: function(){
		this.cache.className = 'open';
	},
	destroy: function(){
		actus.close();
		close.menu(1);
		header.unfix(1);
		$$.tag('footer').className = '';
		var ref = document.body.classList[0];
		if(ref in destroy)
			destroy[ref]();
	},
	parse: function(data){
		var raw = document.createElement('div');
		raw.innerHTML = data;
		this.new = raw.get('#main');
		this.newClass = raw.get('#page').textContent;
		this.newTitle = raw.get('title').textContent;
	},
	replace: function(){
		document.body.replaceChild(this.new, this.main);
		this.main = this.new;
		document.body.className = this.newClass;
		document.title = this.newTitle;
	},
	bind: function(dom){
		window.scrollTo(0, 0);
		reveal.init();
		var classe = this.newClass || document.body.className;
		classe = classe.split(' ')[0];
		if(classe in init)
			init[classe]();
	},
	show: function(){
		this.cache.className = '';
	}
};
$$.ready(page.init);
