/*--------------------------------------------------------------
CALIBRAGE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Calibres
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Calibres
--------------------------------------------------------------*/
var calibres = {
	init: function(){
		this.balance.opened = false;
		this.wrapper = $$.id('calibres');
		this.table = this.wrapper.get('tbody');
		this.title = this.wrapper.get('h2');
		this.bgs = $$.all('.intro .bgs div');
		this.preload();
	},
	preload: function(){
		$$.ajax.post('/fruit').then(function(res){
			var data = JSON.parse(res);
			calibres.vegetables = data;
		});
	},
	balance: {
		open: function(type){
			if (type == 'fruits') {
				this.opened = true;
                $$.first('.intro').className = 'intro open';
                $$.first('.balance.fruits').classList.add('open');
                $$.first('.intro .bgs').className = 'bgs';
			}
			else{
				this.opened = true;
                $$.first('.intro').className = 'intro open';
				$$.first('.balance.legumes').classList.add('open');
                $$.first('.intro .bgs').className = 'bgs';
			}
		},
		close: function(){
			this.opened = false;
			$$.first('.intro').className = 'intro';
			if ($$.first('.fruits').classList.contains('open')) {
				$$.first('.balance.fruits').classList.remove('open');
			} else {
				$$.first('.balance.legumes').classList.remove('open');
			}
			$$.timeout(1000, function(){
				calibres.wrapper.className = 'hidden';
			});
		}
	},
	select: function(el){
		this.balance.opened = false;
		if ($$.first('.fruits').classList.contains('open')) {
			$$.first('.balance.fruits').className = 'balance fruits';
		} else {
			$$.first('.balance.legumes').className = 'balance legumes';
		}
		this.empty();
		this.title.textContent = el.title;
		var id = el.getAttribute('data-id');
		$$.first('.bgs .on').classList.remove('on');
		$$.id('bg' + id).classList.add('on');
		var vegetable = this.vegetables[id];
		var i = vegetable.length;
		while(i--){
			this.addRow(vegetable[i]);
		}
		$$.timeout(500, function(){
			calibres.scroll(1);
		});
	},
	empty: function(){
		this.table.removeChildren();
	},
	addRow: function(cols){
		var row = document.createElement('tr');
		for(var i in cols){
			row.appendChild(this.addCol(cols[i]));
		}
		this.table.appendChild(row);
	},
	addCol: function(texte){
		var col = document.createElement('td');
		col.textContent = texte;
		return col;
	},
	scroll: function(down, type){
		scroll.disable();
		$$.first('.intro').className = down ? 'intro min' : 'intro open';
		calibres.wrapper.className = 'anim';
		$$.timeout(1000, function(){
			calibres.wrapper.className = down ? '' : 'hidden';
			if (!down) {
				if (type == 'fruits') {
					$$.first('.balance.fruits').className = 'balance fruits';
					$$.first('.balance.fruits').className = down ? 'balance fruits' : 'balance fruits open';
				} else {
					$$.first('.balance.legumes').className = 'balance legumes';

					$$.first('.balance.legumes').className = down ? 'balance legumes' : 'balance legumes open';
				}
			}
			calibres.balance.opened = !down;
			scroll.enable();
		});
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.calibrage = function(){
	calibres.init();
}


/*
ATTENTION
---------

Faudra faire gaffe au scroll lors de la mise en place de l'ajax.
Possible que ça foute la merde.
Donc faudra faire une passe à travers chacun des fichiers .js pour s'assurer
que rien ne risque de poser de problème lors du passage d'une page à l'autre.

*/
