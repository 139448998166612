/*--------------------------------------------------------------
SCROLL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Scroll
- Memo
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Scroll
--------------------------------------------------------------*/
var scroll = {
	enabled: true,
	keys: [0, 32, 33, 34, 35, 36, 37, 38, 39, 40],
	prevent: function(e){
		e = e || window.event;
		if(e.preventDefault)
			e.preventDefault();
		e.returnValue = false;
	},
	preventKeys: function(e){
		if(scroll.keys.indexOf(e.keyCode)){
			scroll.prevent(e);
			return false;
		}
	},
	disable: function(){
		window.addEventListener('DOMMouseScroll', this.prevent, false);
		window.onwheel = this.prevent;
		window.onmousewheel = document.onmousewheel = this.prevent;
		// window.ontouchmove = this.prevent;
		document.onkeydown = this.preventKeys;
		this.enabled = false;
	},
	enable: function(){
		window.removeEventListener('DOMMouseScroll', this.prevent, false);
		window.onmousewheel = document.onmousewheel = null;
		window.onwheel = null;
		// window.ontouchmove = null;
		document.onkeydown = null;
		this.enabled = true;
	},
	toggle: function(){
		if(this.enabled)
			this.disable();
		else
			this.enable();
	}
};



/*--------------------------------------------------------------
	Memo
--------------------------------------------------------------*/
/*
left: 37
up: 38
right: 39
down: 40
spacebar: 32
pageup: 33
pagedown: 34
end: 35
start: 36
*/