/*--------------------------------------------------------------
ORGANISATION  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Pie
- Init
- Counter
- Select
- Cook
- Percent
- Init
- Destroy
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Pie
--------------------------------------------------------------*/
function Pie(id){
	this.pie = $$.id(id);
	this.total = 873;
	this.coords = [];
	this.p = this.pie.get('p');
	this.parts = [];
	this.texts = this.pie.getAll('div');
	this.slices = this.pie.getAll('circle');
	this.qte = this.texts.length;
	this.inc = 0;
	this.interval = null;
	this.init();
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
Pie.prototype.init = function(){
	var $ = this;
	this.texts.forEach(function(text, index){
		$.parts[index] = parseInt(text.textContent);
	});
	this.cook();
	this.interval = new Interval(3000, function(){
		$.inc++;
		$.inc = $.inc%$.qte;
		$.select($.inc);
	});
	this.pie.onclick = function(){
		$.interval.stop();
	};
};



/*--------------------------------------------------------------
	Counter
--------------------------------------------------------------*/
Pie.prototype.counter = function(selected){
	var $ = this;
	this.p.className = 'hidden';
	$$.timeout(300, function(){
		$.p.innerHTML = $.texts[selected].innerHTML;
	});
	$$.timeout(600, function(){
		$.p.className = '';
	});
}



/*--------------------------------------------------------------
	Select
--------------------------------------------------------------*/
Pie.prototype.select = function(selected){
	var $ = this;
	selected = parseInt(selected);
	this.texts.forEach(function(el){
		el.classList.remove('hidden');
	});
	this.texts[selected].classList.add('hidden');
	this.slices.forEach(function(slice){
		var nbr = slice.getAttribute('class');
		nbr = parseInt(nbr.charAt(1));
		nbr = (nbr + selected) % $.qte;
		slice.style.strokeDasharray = $.coords[nbr][0];
		slice.style.strokeDashoffset = $.coords[nbr][1];
		slice.setAttribute('data-index', nbr);
		$.counter(selected);
	});
};



/*--------------------------------------------------------------
	Cook
--------------------------------------------------------------*/
Pie.prototype.cook = function(){
	var $ = this;
	var old = 0;
	var oldAngle = 0;
	this.slices.forEach(function(slice, index){
		var angle = $.parts[index];
		var size = $.percent(angle);
		$.coords[index] = [size + ' ' + $.total, -old];
		slice.setAttribute('data-index', index);
		slice.style.strokeDasharray = $.coords[index][0];
		slice.style.strokeDashoffset = $.coords[index][1];
		$.placeText(index, (angle/2 + oldAngle));
		old += size;
		oldAngle += angle;
		slice.onclick = function(){
			if(slice.getAttribute('class') != 'c0')
				$.select(slice.getAttribute('data-index'));
		};
	});
};



/*--------------------------------------------------------------
	Place text
--------------------------------------------------------------*/
Pie.prototype.placeText = function(index, angle){
	var $ = this;
	var a = angle*3.6;
	var r = parseInt(this.slices[0].getAttribute('r'));
	var text = this.texts[index];
	var orig = text.className;

	if(a < 90)
		text.className = 't r';
	else if(a < 180)
		text.className = 'b r';
	else if(a < 270)
		text.className = 'b l';
	else
		text.className = 't l';
	text.className += ' ' + orig;

	var x = Math.round(r * Math.sin(a * Math.PI / 180)) + r + 9;
	var y = Math.round(r * -Math.cos(a * Math.PI / 180)) + r + 9;

	text.style.left = x + 'px';
	text.style.top = y + 'px';

	text.onclick = function(){
		$.select(index);
	};
};



/*--------------------------------------------------------------
	Percent
--------------------------------------------------------------*/
Pie.prototype.percent = function(num){
	return ((num * this.total) / 100);
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
var pie1, pie2;
init.organisation = function(){
	$$.timeout(2000, reveal.inview);
	pie1 = new Pie('pie1');
	pie2 = new Pie('pie2');
};


/*--------------------------------------------------------------
	Destroy
--------------------------------------------------------------*/
destroy.organisation = function(){
	pie1 = null;
	pie2 = null;
};