/*--------------------------------------------------------------
HISTOIRE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Histoire
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Histoire
--------------------------------------------------------------*/
var histoire = {
	h: document.body,
	limit: window.innerWidth > 425 ? 6 : 9,
	init: function(){
		this.current = 0;
	},
	next: function(){
		this.go2(this.current+1);
	},
	prev: function(){
		this.go2(this.current-1);
	},
	go2: function(step){
		if(step > this.limit)
			return;
		else if(step > 0)
			header.fix('h');
		else
			header.unfix(1);
		var i = 1;
		var classes = 'histoire';
		while(i <= step){
			classes += ' s' + i;
			i++;
		}
		this.h.className = classes;
		this.current = step;
	}
};


/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.histoire = function(){
	histoire.init();
}
