/*--------------------------------------------------------------
LA GAMME  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Gamme
- Bgs intro
- Discover
- Parallax
- Go2 product
- Corbeilles
- Informations commande
- Init
- Destroy
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Gamme
--------------------------------------------------------------*/
var gamme = {
	first: 73,
	init: function(){
		this.links = $$.all('.intro a');
		this.bgs = $$.all('.intro .bgs div');
		this.setBg(this.first);
		this.links.forEach(function(el){
			el.onmouseenter = function(){
				gamme.setBg(el.getAttribute('data-id'));
			};
		});
	}
};



/*--------------------------------------------------------------
	Bgs intro
--------------------------------------------------------------*/
gamme.setBg = function(nbr){
	var i = this.links.length;
	while(i--){
		this.links[i].parentNode.className = this.links[i].getAttribute('data-id') == nbr ? 'hover' : '';
		this.bgs[i].className = this.bgs[i].getAttribute('data-id') == nbr ? 'hover' : '';
	}
};



/*--------------------------------------------------------------
	Ajax
--------------------------------------------------------------*/
gamme.ajax = {
	ms: function(){
		$$.id('main').className = 'out';
		this.call();
	},
	ss: function(){
		page.destroy();
		$$.ajax.post(page.url).then(function(res){
			page.parse(res);
			var sidebar = page.new.get('.sidebar');
			var intro = page.new.get('.intro');
			var produits = page.new.get('.hide');
			intro.className = 'intro fadeIn';
			sidebar.className = 'sidebar fadeIn';
			page.main.appendChild(sidebar);
			page.main.appendChild(intro);
			page.main.appendChild(produits);
			document.body.className = page.newClass;
			document.title = page.newTitle;
			page.bind();
			$$.timeout(500, function(){
				page.bol = false;
				page.old = page.url;
				intro.className = 'intro';
				page.main.removeChild($$.first('.sidebar'));
				page.main.removeChild($$.first('.intro'));
				page.main.removeChild($$.first('.hide'));
			});
		});
	},
	sm: function(){
		gamme.first = $$.first('.produits').getAttribute('data-id');
		if($$.first('.hide.show')){
			$$.first('.hide.show').className = 'hide';
			$$.timeout(1000, function(){
				document.body.className = 'categorie out';
				$$.timeout(500, gamme.ajax.call);
			});
		}else{
			document.body.className = 'categorie out';
			$$.timeout(500, this.call);
		}
	},
	call: function(callback){
		page.destroy();
		$$.ajax.post(page.url).then(function(res){
			if(callback) callback();
			page.parse(res);
			page.replace();
			page.bind();
			$$.timeout(500, function(){
				page.bol = false;
				page.old = page.url;
			});
		});
	}
};



/*--------------------------------------------------------------
	Discover
--------------------------------------------------------------*/
gamme.discover = function(){
	if(window.innerWidth > 425){
		gamme.produits = $$.all('.produits section');
		$$.first('.hide').className = 'hide show';
		gamme.produits[0].classList.remove('hidden');
		gamme.centres = $$.all('.produits .centre');
		if(!isTouch) document.body.addEventListener('mousemove', gamme.parallax);
		if(gamme.produits[0].className == 'corb'){
			gamme.corb.num = 1;
			gamme.corb.play();
		}
	}else{
		window.scrollTo(0, window.innerHeight);
	}
};
gamme.undiscover = function(){
	gamme.produits = $$.all('.produits section');
	$$.first('.show').className = 'hide';
	gamme.produits.forEach(function (produit) {
		produit.classList.add('hidden');
	});
	if(!isTouch) document.body.removeEventListener('mousemove', gamme.parallax);
	if($$.first('.gammes .active')){
		$$.first('.gammes .active').classList.remove('active');
		$$.first('.gammes li').classList.add('active');
	}

	if(gamme.produits[0].classList.contains('corb')){
		gamme.corb.pause();
		gamme.corb.num = 0;
		var corbs = $$.all('.corb');
		var i = corbs.length;
		while(i--){
			corbs[i].className = 'corb hidden';
		}
	}
};



/*--------------------------------------------------------------
	Parallax
--------------------------------------------------------------*/
gamme.parallax = function(e){
	var offset = window.innerHeight/3;
	var cadre = (window.innerWidth - offset)/2;
	var x = e.clientX - cadre - offset;
	var y = e.clientY - window.innerHeight/2;
	var o = gamme.centres.length;
	while(o--){
		var els = gamme.centres[o].getAll('img, p');
		var i = els.length;
		while(i--){
			var r = (i+1)*20;
			els[i].style.left = x/r + 'px';
			els[i].style.top = y/r + 'px';
		}
	}
};



/*--------------------------------------------------------------
	Go2 product
--------------------------------------------------------------*/
gamme.go2 = function(ul, e){
	if(e.target.nodeName == 'UL') return;
	var lis = ul.children;
	var li = e.target.nodeName == 'LI' ? e.target : e.target.parentNode;
	var i = Array.prototype.indexOf.call(lis, li);
	var o = lis.length;
	while(o--){
		lis[o].className = '';
		gamme.produits[o].classList.add('hidden');
	}
	li.className = 'active';
	gamme.produits[i].classList.remove('hidden');
};



/*--------------------------------------------------------------
	Corbeilles
--------------------------------------------------------------*/
gamme.corb = {
	num: 1,
	play: function(){
		var corbs = $$.all('.corb');
		this.int = new Interval(3500, function(){
			var i = corbs.length;
			while(i--){
				corbs[i].className = i == gamme.corb.num ? 'corb' : 'corb hidden';
			}
			gamme.corb.num = (gamme.corb.num+1)%corbs.length;
		});
	},
	pause: function(){
		if(gamme.corb.int) this.int.stop();
	}
};



/*--------------------------------------------------------------
	Informations commande
--------------------------------------------------------------*/
gamme.infos = {
	open: function(a){
		gamme.corb.pause();
		a.nextElementSibling.className = 'infos visible';
	},
	close: function(cache){
		gamme.corb.play();
		cache.parentNode.className = 'infos';
	}
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
init.gamme = function(){
	gamme.init();
};



/*--------------------------------------------------------------
	Destroy
--------------------------------------------------------------*/
destroy.categorie = function(){
	gamme.corb.pause();
	if(!isTouch) document.body.removeEventListener('mousemove', gamme.parallax);
};
