/*--------------------------------------------------------------
REVEAL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Reveal
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Reveal
--------------------------------------------------------------*/
var reveal = {
	init: function(){
		this.all = $$.all('.reveal');
		this.inview();
		$$.timeout(2000, this.inview);
		window.addEventListener('scroll', this.inview);
		window.addEventListener('resize', this.inview);
	},
	destroy: function(){
		window.removeEventListener('scroll', this.inview);
		window.removeEventListener('resize', this.inview);
	},
	inview: function(){
		reveal.all.forEach(function(el){
			if(!el.classList.contains('inview') && el.getBoundingClientRect().top - window.innerHeight < -window.innerHeight/4)
				el.classList.add('inview');
		});
	}
};