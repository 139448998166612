/*--------------------------------------------------------------
SCRIPT  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- isTouch
- First
- Toggle / Close / Init / Destroy
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	isTouch
--------------------------------------------------------------*/
var isTouch = 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;



/*--------------------------------------------------------------
	First
--------------------------------------------------------------*/
var first = true;
$$.ready(function(){
	if(first) first = false;
	else document.body.classList.remove('first');
});



/*--------------------------------------------------------------
	Toggle / Close / Init / Destroy
--------------------------------------------------------------*/
var toggle = {};
var close = {};
var init = {};
var destroy = {};